<template>
  <v-app class="vapp">
    <v-banner
      single-line
      :height="screenHeight"
      class="banner"
    >
    <!-- animate__animated animate__fadeInRightBig -->
      <video ref="videos" id="video" loop="loop" muted="muted"
             preload="auto" autoplay="autoplay"
             poster="https://h5.ophyer.cn/official_website/other/metaverse-banner1-img2.png"
             type="video/mp4"
             class="video" src="https://h5.ophyer.cn/official_website/other/metaverse-video8.mp4" style="height: 100%;object-fit: cover"></video>
      <div class="video-up"></div>
       
      <div class="banner-box" ref="bannerTitle" :class="addClass ? 'aaa' : ''">
        <!-- <p class="banner-title">数字世界</p> -->
        <p class="banner-title">飞天元宇宙介绍</p>
        <p class="banner-text">开放沉浸的3D虚拟世界，720度内部VR全景 <br> 运用你的想象力，让虚拟与现实完美融合</p>
      </div>
      <div class="bottom"></div>
      <!-- <v-img
        src="https://h5.ophyer.cn/official_website/banner/metaverse-pc-home-banner.png"
        width="100%" height="100%" cover
        :transition="false">
        <div class="banner-box" ref="bannerTitle" :class="addClass ? 'aaa' : ''">
          <p class="banner-title">飞天元宇宙</p>
          <p class="banner-text">开放沉浸的3D虚拟世界，720度内部VR全景 <br> 运用你的想象力，让虚拟与现实完美融合</p>
        </div>
      </v-img> -->
      <!-- <div class="video-up" ref="bgTop"></div> -->
      <!-- <div class="bottom"></div> -->
       <!-- src="https://h5.ophyer.cn/official_website/vrH5/h5/index.html" -->
        <!-- src="https://wmy-vr.ophyer.com/h5/index.html" -->
      <!-- <iframe 
        src="https://h5.ophyer.cn/official_website/vrH5/h5/index.html"
        scrolling="auto"
        width="100%"
        height="100%"
        :frameborder="0"
        ref="mapFrame"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        oallowfullscreen="true"
        msallowfullscreen="true"
      ></iframe> -->
    </v-banner>

    <!-- 开启你的创意之旅 -->
    <v-card class="warps warp1 wow animate__fadeInUp" data-wow-duration="1s" data-wow-offset="10" :flat="true">
      <div class="metaverse-title">
        <p>开启你的创意之旅</p>
      </div>
      <div class="con">

        <v-card :flat="true" height="273" width="590" min-width="400" v-for="(v,k) in warp1" :key="k">
          <div class="f-box">
            <div class="div-filter"></div>
            <img :src="v.bg" />

          </div>
          <div class="z-box">
            <div class="box">
              <v-card-title> {{ v.title }} </v-card-title>
              <v-card-text> {{ v.text }} </v-card-text>
              <div class="btn">
                <!-- <button v-if="v.btn" @click="currentHover = true"> {{ v.btn }} </button> -->
              </div>
            </div>
            <span class="more" v-if="v.more" @click="handleLink(v)">
              {{ v.more }}
            </span>
          </div>
     
        </v-card>
      </div>
    </v-card>

    <!-- 什么是飞天元宇宙 -->
    <v-card class="warps warp2" :flat="true">
      <div class="metaverse-title wow animate__fadeInUp" data-wow-duration="1s">
        <p>什么是飞天元宇宙</p>
        <p>飞天元宇宙是一个开放的，自由的的创意社区，由所有开发者们共同打造。将内容创造、社交、娱乐体验结合在一起，所有人一起创造，探索享受这个世界</p>
      </div>
      <div class="con">
        <div class="box-1 box wow animate__fadeInUp" data-wow-duration="1s">
          <div class="box-img">
            <img src="https://h5.ophyer.cn/official_website/other/metaverse-home-warp2-img01-1.png" alt="">
          </div>
          <div class="box-text box-text-left">
            <p>沉浸式体验</p>
            <p>庞大的3D虚拟世界场景，结合720度VR全景，让你在虚拟的 世界中体验现实发生的一切，感受3D沉浸式交互体验</p>
          </div>
        </div>

        <div class="box-1 box wow animate__fadeInUp" data-wow-duration="1s">
          <div class="box-text box-text-w">
            <p>创意轻松实现</p>
            <p>强大的工具，图形化界面，简单易用，无需专业基础也可以快速创作属于自己的世界，让创意不在只是创意</p>
          </div>
          <div class="box-img">
            <img src="https://h5.ophyer.cn/official_website/other/metaverse-home-warp2-img02-1.png" alt="">
          </div>
        </div>

        <div class="box-1 box wow animate__fadeInUp" data-wow-duration="1s">
          <div class="box-img">
            <img src="https://h5.ophyer.cn/official_website/other/metaverse-home-warp2-img03-1.png" alt="">
          </div>
          <div class="box-text box-text-left">
            <p>自由的社区</p>
            <p>聊天，交友，组队一起探索，在这里你可以与小伙伴一起体验和创造世界，随时随地的交流互动</p>
          </div>
        </div>

        <div class="box-1 box wow animate__fadeInUp" data-wow-duration="1s">
          <div class="box-text box-text-w">
            <!-- <p>创意也可以变现</p> -->
            <p>创意任意分享</p>
            <!-- <p>创意不仅是有趣，还可以为自己带来收入，所有玩家创造的内容都可以交易出售</p> -->
            <p>将有趣的创意分享给他人，带来更多成就感</p>
          </div>
          <div class="box-img">
            <img src="https://h5.ophyer.cn/official_website/other/metaverse-home-warp2-img04-1.png" alt="">
          </div>
        </div>
      </div>
    </v-card>

    <v-card class="warps warp3" :flat="true" width="100%" height="386" img="https://h5.ophyer.cn/official_website/other/metaverse-home-footer-bg2.png">
      <div class="con">
        <p>快来加入创作队伍吧</p>
        <!-- <button @click="currentHover = true"> 下载编辑器 </button> -->
      </div>
    </v-card>

    <div class="dialog" v-show="currentHover" @click.stop="currentHover = false">
      <transition name="fade" mode="out-in" @click.stop>
        <div class="content-tryout" @click.stop>
          <v-card color="transparent" flat  @click.stop>
            <v-card-title class="justify-end pb-0 close-btn" @click.stop="currentHover = false">
              <img width="11px" height="12px" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-clone.png" alt="">
              </v-card-title>
            <img style="min-height: 180px;width: 480px;" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-bg.png" alt="">
            <div class="box">
              <img width="48px" height="48px" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-icon.png" alt="">
              <div>
                <p>010-63037996</p>
                <p>客服热线</p>
              </div>
            </div>
          </v-card>
        </div>
      </transition>
    </div>

  </v-app>
</template>

<script>
import { mapState } from "vuex";
// import newState from "@/utils/newState";

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      currentHover: false,
      screenHeight: window.innerHeight,
      addClass: false,
      form: {
        name: "",
        phone: "",
        conpanyName: "",
        address: ""
      },
      warp1: [
        {
          title: '创造你的领地',
          text: '提供强大的可视化的创意工具，简易的拖拽即可搭建720度VR场景，轻松创造3D素材。在这里，你可以创造出你想要的一切',
          bg: 'https://h5.ophyer.cn/official_website/other/metaverse-home-warp1-img-z1-02.png',
          btn: '下载编辑器',
          more: '了解更多',
          router: "/develop/Home",
          isNewWindow: true,
        },
        {
          title: '飞天元宇宙',
          text: '你可以与好友、陌生人，一起随心所欲的探索这个世界，做你想做的一切。加入我们一起来让世界更加丰富多彩。',
          bg: 'https://h5.ophyer.cn/official_website/other/metaverse-home-warp1-img-z2-02.png',
          // more: '去体验',
          router: "/Course",

        }
      ],
      imgSrc: ['https://h5.ophyer.cn/official_website/banner/metaverse-h5-home-banner.png'],
 
    }
  },
 
  computed: {
    ...mapState(["officialWebsite"]),
  },
    created() {
  },
  mounted() {
    new this.$wow.WOW().init()
    
    let _this = this
    setTimeout(()=>{
      _this.addClass = true
    }, 500)
    setTimeout(()=>{
      _this.$refs.bannerTitle.style.opacity = 1
    }, 2000)


    // window.addEventListener('mousewheel',this.handleScroll, true)
    // let that = this
    // that.$nextTick(()=>{
    //   let mapFrame = that.$refs["mapFrame"];
    //   let config = this.$_.cloneDeep(newState.config);
    //   config.scenes.push(
    //       {
    //         id: "img_preview", //当前场景的Id
    //         options: {
    //           camera: {
    //             fov: 95,
    //             minFov: 90,
    //             maxFov: 100
    //           },
    //           mask: {
    //             skyRotate: true,
    //             landRotate: true
    //           },
    //           orbitControls: {
    //             maxAzimuthAngle: Infinity,
    //             maxPolarAngle: Infinity,
    //             minAzimuthAngle: -Infinity,
    //             minPolarAngle: 0,
    //             rotateSpeed: -0.5,
    //             minDistance:0,
    //             maxDistance:100
    //           }
    //         },
    //         initCameraPosition: [
    //           -2.26111729969225,
    //           0.33068442631847933,
    //           -1.9437068624725407
    //         ],
    //         skybox: Array.isArray(this.imgSrc) ? this.imgSrc : [
    //           this.imgSrc
    //         ],
    //         autoRotate: false,
    //         hotspots: {}
    //       }
    //   );
    //   config.footerOneList[0].footerTwoList[0].footerThreeList.push(
    //       {
    //         selected: false,
    //         threeId: "img_preview",
    //         imgUrl: Array.isArray(this.imgSrc) ? this.imgSrc[0] : this.imgSrc,
    //         name: "默认场景",
    //         viewSrc: Array.isArray(this.imgSrc) ? this.imgSrc[0] : this.imgSrc,
    //         showRecord: false,
    //         threeScene: false,//是否为3D场景
    //         setThreeId: 1,//替换图片时无法判断当前是否存在，先加一个Id
    //         isTurn: false,//默认为ture,翻转场景
    //       }
    //   );
    //   config.customMenu.switchMenu.SceneSelection = false;
    //   config.customMenu.switchMenu.commentSwitch = false;
    //   config.customMenu.switchMenu.SceneShare = false;
    //   config.customMenu.switchMenu.toPraise = false;
    //   config.basisChild.SceneSelection = false;
    //   config.basisChild.Automatic = true;
    //   console.log(config)
    //   mapFrame.onload = function () {
    //     setTimeout(()=>{
    //       that.handleToPostMessage("config", config);
    //     },1200);
    //   };
    // })
    window.onmessage = function (event) {
      if (event.data.form == "threejs") {
        switch (event.data.type) {
          case "orbitChange":
            // if(that.isFov == event.data.fov) {
              // console.log(that.$refs['gbTop'])
              // that.$refs.gbTop.style.zIndex = "1"
            // }
            // that.isFov = event.data.fov
            // console.log(that.isFov)
            break;
        }
      }
    }
  },
  methods: {
   
    handleLink(content) {
      if(content.isNewWindow){
        window.open(window.location.protocol + "//" + window.location.host + content.router)
      }else {
        this.$router.push(content.router)
      }
    },
    handleToPostMessage(type, content) {
      let that = this;
      let mapFrame = that.$refs["mapFrame"];
      let iframeWin = mapFrame.contentWindow;
      iframeWin? iframeWin.postMessage({type, content}, "*"):'';
    },
  }
}
</script>

<style lang="scss" scoped>
::deep .v-application ::v-deep .v-application--wrap {
  width: 100% !important;
}
.v-application, .v-card {
  background: #191b1f;
}

.banner{
  position: relative;
  overflow: hidden;
  min-width: 1400px;
  background: #191b1f;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    background: unset !important;
    height: 100%;
  }
  ::v-deep .v-image {
    background: #191b1f;
  }
  iframe {
    position: relative;
    z-index: 1;
    display: none;
  }
  #video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
    width: 100%;
  }
  //@media (min-width: 1918px) {
  //  #video {
  //    width: 2120px;
  //  }
  //}
  .video-up {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0,0,0,.0);
  }
  .banner-box {
    position: absolute;
    z-index: 3;
    color: #fff;
    padding-left: 80px;
    top: 50%;
    transform: translate(0%, -50%) !important;
    // transition: transform .5s cubic-bezier(.39,.575,.565,1), opacity .3s cubic-bezier(.67,.19,.94,.81);
    opacity: 0;
    .banner-title {
      font-size: 56px;
      font-weight: 600;
      line-height: 78px;
      text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
    }
    .banner-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
    }
  }
  .aaa {
    animation: opacity-one 2s 1;
    // transition-delay: 2s;
    // opacity: 1;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    z-index: 50;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(22,22,26,0)),color-stop(85%,#191b1d));
    background-image: linear-gradient(rgba(22,22,26,0),#191b1d 85%);
    background-origin: padding-box;
    background-size: auto;
  }
}

.metaverse-title {
  margin-top: 100px;
  color: #FFFFFF;
  text-align: center;
  position: relative;
  z-index: 6;
  p:nth-child(1) {
    font-weight: 500;
    font-size: 32px;
  }
  p:nth-child(2) {
    margin: 0 auto;
    max-width: 600px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.8);
  }
}


.warps {
  margin: 0 auto;
}

.warp1 {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  z-index: 2;
  .con {
    display: flex;
    position: relative;
  }
  .v-card {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 10px !important;
    &:hover .f-box {
      transform: scale(1.04)  translateZ(0);
      
    }
  }
  .f-box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    // border: 1px solid #3e4042;
    margin: 10px !important;
    padding: 28px 0 20px 28px;
    background: unset !important;
    position: relative;
    z-index: 4;
    transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1);
    background: linear-gradient(180deg, rgba(105, 116, 125, 0.2) 0%, rgba(49, 35, 216, 0.1) 100%) !important;
    .div-filter {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.25);
      backdrop-filter: saturate(180%) blur(6px);
      -webkit-backdrop-filter: saturate(180%) blur(6px);
      border-radius: 5px;
      // border-right: 1px solid #3e4042;
      // border-bottom: 1px solid #3e4042;
      border: 2px solid #3e4042;
      box-sizing: border-box;
    }
    img {
      position: absolute;
      width: 290px;
      right: 0;
      bottom: 0;
      z-index: 3;
    }
  }
  .z-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 7;
    margin: 10px !important;
    padding: 30px 0 20px 28px;
    .box {
      position: relative;
      z-index: 6;
    }
    .v-card__title {
      font-size: 18px !important;
      color: #FFFFFF !important;
      padding: 0 !important;
      margin-bottom: 20px;
    }
    .v-card__text {
      width: 56%;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
      line-height: 22px;
      padding: 0 !important;
    }
    .btn {
      margin-top: 30px;
      margin-bottom: 25px;
      height: 46px;
      button {
        color: #FFFFFF;
        font-size: 14px;
        width: 110px;
        height: 46px;
        background: #191626;
        border-radius: 5px;
        border: 1px solid #dcdce0;
      }
    }
    .more {
      position: relative;
      z-index: 3;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
      margin-bottom: 20px;
      &::after {
        content: "";
        background-image: url("./../../../assets/images/home/h5-case-icon.png");
        background-size: 100%;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -27px;
        top: 3px;
      }
    }

  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    content: "";
    width: 1268px;
    height: 665px;
    background: url("https://h5.ophyer.cn/official_website/other/metaverse-home-bg01-1.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}


.warp2 {
  width: 100%;
  position: relative;
  margin-bottom: 70px;
  .con {
    position: relative;
    z-index: 2;
    margin: 70px auto 0;
    max-width: 1200px;
    width: 1200px;
  }
  .box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 25px;
  }
  .box-1 {
    .box-img {
      width: 600px;
      // height: 250px;
      img {
        width: 600px;
      }
    }
    .box-text {
      p:nth-child(1) {
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
        position: relative;
        &::after {
          content: '';
          width: 40px;
          height: 3px;
          background: #FFFFFF;
          position: absolute;
          left: 0;
          top: -16px;
        }
      }
      p:nth-child(2) {
        margin-top: 5px;
        max-width: 370px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .box-text-w {
      margin-left: 150px;
    }
    .box-text-left {
      // margin-left: -50px;
      position: relative;
      left: -50px;
    }
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: "";
    width: 1920px;
    height: 1378px;
    background: url("https://h5.ophyer.cn/official_website/other/metaverse-home-bg02-2.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.warp3 {
  margin: 0 auto;
  .con {
    text-align: center;
    padding-top: 40px;
    p {
      font-size: 32px;
      font-weight: 500;
      color: #FFFFFF;
      text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
    }
    button {
      color: #FFFFFF;
      font-size: 14px;
      width: 128px;
      height: 46px;
      background: #191626;
      border-radius: 5px;
      border: 1px solid #dcdce0;
    }
  }
}


.dialog {
  position: fixed;
  z-index:1001;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  .content-tryout{
    width: 480px;
    height: 307px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #FFFFFF;
    box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
    .v-card {
      cursor: default;
    }
    .close-btn{
      position: absolute;
      top: 0;
      right: 0;
      color: rgba(0, 0, 0, 0.6) !important;
      cursor: pointer;
    }
    .box {
      margin-top: 35px;
      display: flex;
      align-items: center;
      margin-left: 35px;
      img {
        width: 48px;
        height: 48px;
        margin-right: 5px;
      }
      p {
        margin-bottom: 0;
      }
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      p:nth-child(2) {
        margin-top: 5px !important;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

</style>
